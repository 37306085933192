// HomePage.tsx
import React from "react";
import Client from "./components/Client.tsx";
import Product from "./components/Product.tsx";
import Whysection from "./components/Whysection.tsx";
import Heroarea from "./components/Heroarea.tsx";

import "./home.css";
import "./globals.css";
import "./responsive.css";

const HomePage: React.FC = () => {
  return (
    <main>
      <Heroarea />
      <hr />
      <Whysection />
      <hr />
      <Product />
      {/* <Subs /> */}
      <hr />
      <Client />
      {/* <Footer /> */}
    </main>
  );
};

export default HomePage;
