import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header.tsx";
import HomePage from "./pages/home/Home.tsx";
import ProductPage from "./pages/product/product.tsx";
import SingleProductPage from "./pages/SingleProd/SingleProd.tsx";

const App: React.FC = () => {
  return (
   
      <div className="App">
        <Header />
        {/* Switch is replaced by Routes in react-router-dom v6 */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/singleproduct/:id" element={<SingleProductPage />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
  
  );
};

export default App;
